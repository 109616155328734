import logo from "./logo.svg";
import "./App.css";
import { ConnectButton } from "360dialog-connect-button";
function App() {
  const handleCallback = (callbackObject) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

    console.log("client ID: " + callbackObject.client);
    console.log("channel IDs: " + callbackObject.channels);
  };
  return (
    <div className="App">
      <header className="App-header">
        Connect with Ginger WhatsApp
        <ConnectButton partnerId={"TmiYAsPA"} callback={handleCallback} />
      </header>
    </div>
  );
}

export default App;
